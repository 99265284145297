<template>
  <SupplierProductPage
    product_router="EndUserGameProduct"
    page_title="Game"
    user_type="enduser"
  ></SupplierProductPage>
</template>
  
<script>
import SupplierProductPage from '@/components/endUser/suppliersPages/SupplierProductPage.vue';

export default {
  name: 'GameProduct',
  components: {
    SupplierProductPage
  },
}
</script>